import React from "react";
import Link from "next/link";

export default function FourOhFour() {
  return (
    <div className="relative min-h-screen bg-gray-100 pt-16 pb-20 px-4 sm:px-6 ">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">없는 주소입니다</h2>

          <Link href="/home">
            <label className="text-center ">
              <div className="mx-auto w-52 m-8 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">첫 화면으로 가기</div>
            </label>
          </Link>
        </div>
      </div>
    </div>
  );
}
